<template>
    <v-card
        :height="listheight"
        min-width="250px"
        max-width="250px"
        outlined
        color="grey lighten-2"
        class="ml-3 mr-0 mb-3"
    >
        <v-card-text class="pa-2">
            <h5 color="black">{{ title }}</h5>
            <v-layout row>
                <v-flex lg12>
                    <div :style="`height:` + (listheight-60) + `px` + `; overflow-y: auto`">
                        <ListCard
                                v-for="card in cards"
                                v-bind:key="card.id"
                                :card="card"
                        ></ListCard>
                    </div>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>
<script>
    import ListCard from "./ListCard";
    export default {
        name: "BoardList",
        components: {ListCard},
        data(){
            return {

            }
        },
        props: {
            title: String,
            cards: Array,
            listheight: Number
        },
        methods: {

        },
        mounted(){
        }
    }
</script>

<style scoped>
    .scrollable {
        overflow-y: auto;
    }
</style>