<template>
    <v-card class="mx-0 mt-0 mb-2 pa-0">
        <v-card-text>
            <v-img
                    :lazy-src="card.idAttachmentCoverPreview[0]['url']"
                    :src="card.idAttachmentCoverPreview[1]['url']"
                    contain
                    v-if="card.idAttachmentCover != ''"
            ></v-img>
            <div class="black--text">{{ card.name }}</div>
            <div class="black--text">
                <span class="pr-2">
                    <v-btn
                         x-small
                         color="warning"
                    >
                        <v-icon x-small>ti-time</v-icon>&nbsp;XXXX-XX-XX
                    </v-btn>
                </span>
                <span v-if="card.desc != ''" class="pr-2">
                    <v-icon
                            x-small
                    >ti-align-left</v-icon>
                </span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <span v-if="card.badges.comments > 0" v-on="on" class="pr-2">
                            <v-icon
                                    x-small
                            >ti-comment</v-icon>&nbsp;{{ card.badges.comments }}
                        </span>
                    </template>
                    <span>Comments</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <span v-if="card.badges.attachments > 0" v-on="on" class="pr-2">
                                <v-icon
                                    x-small
                                >ti-clip</v-icon>&nbsp;{{ card.badges.attachments }}
                        </span>
                    </template>
                    <span>Attachments</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <span v-if="card.badges.checkItems > 0" v-on="on">
                            <v-icon
                                    x-small
                            >ti-check-box</v-icon>&nbsp;{{ card.badges.checkItemsChecked +`/`+ card.badges.checkItems}}
                        </span>
                    </template>
                    <span>Checklist items</span>
                </v-tooltip>
            </div>
            <div class="black--text caption d-flex flex-column">
                <span>Supplier: </span>
                <span>OSD: </span>
                <span>Container Qty: </span>
                <span>Total Value: </span>
                <span>Total Volume: </span>
                <span>ETD: {{ card.customFields}}</span>
                <span>ETA: </span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "ListCard",
        props: {
            card: Object,
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>